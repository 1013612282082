label {
  margin-bottom: 0 !important;
}
.show {
  display: block;
}
.hide {
  display: none;
}
.right {
  float: right;
}
.checkboxTitle label {
  padding: 0;
  font-size: 21px;
  line-height: inherit;
  color: #333;
}
.checkboxTitle input {
  margin-top: 10px;
}
.permitItem {
  background-color: #ffffff;
  margin: 15px 0px;
  padding: 15px;
}
.centerContent {
  text-align: center;
  position: absolute !important;
  top: 50%;
}
.textAlignRight {
  text-align: right;
}

.textAlignLeft {
  text-align: left;
}
.alertBox {
  float: right;
  min-width: 50px;
}
.auditItems {
  font-size: 10px;
}
.customEditBtn {
  position: absolute;
  bottom: 0;
}
.active,
.contentUnitMedia .active span {
  color: #333;
}
.contentUnitMedia,
.contentUnitMedia span {
  color: #999;
}
.btnLoad {
  position: absolute;
  bottom: 15px;
}
